<template>
  <div class="mt-1">
    <b-overlay
      :show="initialLoad"
      rounded="sm"
    >
      <b-row>
        <b-col
          md="12"
          class="mb-2"
        >
          <b-button
            variant="primary"
            class="mr-2"
            @click.prevent="handleAddNewMarket"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span>Add New Market</span>
          </b-button>
          <b-button
            variant="secondary"
            @click.prevent="handleCreateNewMarketType"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span>Create New Market Type</span>
          </b-button>
        </b-col>
        <b-col md="12">
          <my-table
            title=""
            :rows="clients"
            :columns="columns"
            :date-range-enabled="true"
            :date-range-field="'date_added'"
            :pagination="pagination"
            :is-loading="isLoading"
          >
            <template v-slot="{props}">
              <span v-if="props.column.field === 'name'">
                <b-img-lazy
                  v-bind="logoProps"
                  :src="props.row.logo"
                  :alt="props.row.name"
                />
                {{ props.row.name }}
              </span>
              <span v-else-if="props.column.field ==='date_added'">
                {{ $helpers.formatDate(props.row.date_added) }}
              </span>
              <span v-else-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      :to="{ name: 'market-branches', params: { marketId: props.row.id } }"
                    >
                      <feather-icon
                        icon="EyeIcon"
                        class="mr-50"
                      />
                      <span>Branches</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click.prevent="handleEditMarket(props.row)"
                    >
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                      />
                      <span>Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click.prevent="handleAddNewBranch(props.row)">
                      <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                      />
                      <span>Add New Branch</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>
            </template>
          </my-table>
        </b-col>
      </b-row>
    </b-overlay>
    <AddNewMarketModal
      :is-show="showAddNewMarketModal"
      @reset-modal="handleResetAddNewMarketModal"
      @add-success="handleAddNewMarketSuccess"
    />
    <EditMarketModal
      :is-show="showEditMarketModal"
      :form="selectedMarket"
      @reset-modal="handleResetAddNewMarketModal"
      @add-success="handleAddNewMarketSuccess"
    />
    <AddEditBranchModal
      :is-show="showAddNewBranchModal"
      :form="selectedMarket"
      @reset-modal="handleResetAddNewMarketModal"
      @add-success="handleAddNewMarketSuccess"
    />
    <CreateNewMarketTypeModal
      :is-show="showCreateNewMarketTypeModal"
      @reset-modal="handleResetAddNewMarketModal"
    />
  </div>
</template>

<script>
import MyTable from '@/components/MyTable.vue'
import axios from '@axios'
import AddNewMarketModal from '@/components/AddNewMarketModal.vue'
import EditMarketModal from '@/components/EditMarketModal.vue'
import AddEditBranchModal from '@/components/AddEditBranchModal.vue'
import CreateNewMarketTypeModal from '@/components/CreateNewMarketTypeModal.vue'

export default {
  name: 'MyMarkets',
  components: {
    MyTable,
    AddNewMarketModal,
    EditMarketModal,
    AddEditBranchModal,
    CreateNewMarketTypeModal,
  },
  data() {
    return {
      logoProps: {
        blank: true,
        blankColor: '#bbb',
        width: 70,
        class: 'mr-2',
      },
      clients: [],
      columns: [
        {
          label: 'ID',
          field: 'id',
          thClass: 'align-middle text-left',
          tdClass: 'align-middle text-nowrap text-left',
          type: 'number',
        },
        {
          label: 'Name',
          field: 'name',
          thClass: 'align-middle',
          tdClass: 'align-middle text-nowrap',
        },
        {
          label: 'Market Type',
          field: 'market.name',
          thClass: 'align-middle',
          tdClass: 'align-middle text-nowrap',
        },
        {
          label: 'Date Added',
          field: 'date_added',
          thClass: 'align-middle',
          tdClass: 'align-middle text-nowrap',
        },
        {
          label: 'Action',
          field: 'action',
          thClass: 'align-middle',
          tdClass: 'align-middle text-nowrap w-10',
          sortable: false,
        },
      ],
      isSubmitting: false,
      isLoading: false,
      pagination: {
        enabled: true,
        mode: 'records',
        perPage: 5,
        perPageDropdown: [5, 10, 15],
      },
      initialLoad: false,
      showAddNewMarketModal: false,
      showEditMarketModal: false,
      showAddNewBranchModal: false,
      showCreateNewMarketTypeModal: false,
      selectedMarket: {},
    }
  },
  created() {
    this.fetchClients()
  },
  methods: {
    async fetchClients() {
      try {
        this.clients = []
        this.isLoading = true
        const response = await axios.get('/clients')
        if (response.status === 200) {
          this.clients = response.data
        }
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        throw new Error('Something went wrong!', error)
      }
    },
    handleAddNewBranch(row) {
      this.selectedMarket = {
        id: row.id,
        image_path: row.logo,
        client: {
          name: row.name,
          type: row.type,
          pic: row.pic,
        },
        branch: {},
      }
      this.showAddNewBranchModal = true
    },
    handleAddNewMarket() {
      this.showAddNewMarketModal = true
    },
    handleCreateNewMarketType() {
      this.showCreateNewMarketTypeModal = true
    },
    handleResetAddNewMarketModal() {
      this.showAddNewMarketModal = false
      this.showEditMarketModal = false
      this.showAddNewBranchModal = false
      this.showCreateNewMarketTypeModal = false
    },
    handleAddNewMarketSuccess() {
      this.fetchClients()
    },
    handleEditMarket(row) {
      this.selectedMarket = {
        id: row.id,
        image_path: row.logo,
        client: {
          name: row.name,
          type: row.type,
          pic: row.pic,
        },
      }
      this.showEditMarketModal = true
    },
  },
}
</script>
